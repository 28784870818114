<template>
   <div class="card">
    <div class="card-content">
      <div class="content">
        <table>
          <tr>
            <td>
              company
            </td>
            <td>
              <o-input placeholder="Aveniq" v-model="company"> </o-input>
            </td>
          </tr>
          <tr>
            <td>
              client_key
            </td>
            <td>
              <o-input placeholder="Client ID" v-model="client_key"> </o-input>
            </td>
          </tr>
           <tr>
            <td>
              available_licenses
            </td>
            <td>
              <o-slider :min="0" :max="1000" ticks v-model="available_licenses"></o-slider>
            </td>
          </tr>
          <tr>
            <td>
              active
            </td>
            <td>
              <o-checkbox v-model="active"></o-checkbox>
            </td>
          </tr>
        </table>
      <section v-if="successNewClient" class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{ successNewClient.password }}
          </p>
          <p class="subtitle">
            Please copy this Client secrect somewhere safe, it can never be restored again
          </p>
        </div>
      </section>
      </div>
      <footer class="card-footer">
        <o-button variant="danger" class="card-footer-item" @click="$emit('close')" >Close</o-button>
        <o-button variant="success" class="card-footer-item" @click="create()" >Create</o-button>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      company: '',
      client_key: '',
      successNewClient: null,
      active: true,
      available_licenses: 0
    }
  },
  methods: {
    create () {
      var body = {
        client_key: this.client_key,
        info: {
          company: this.company,
          active: this.active,
          available_licenses: this.available_licenses
        }
      }
      this.$store.dispatch('tenants/create', body)
        .then(data => {
          this.successNewClient = data
        })
        .catch(error => this.errorMessage(error))
    },
    errorMessage (message) {
      this.$oruga.notification.open({
        message: message,
        rootClass: 'toast-notification',
        variant: 'danger',
        position: 'bottom',
        duration: 5000
      })
    }
  }
}
</script>

<style scss scoped>
</style>
