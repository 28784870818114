<template>
  <div>
    <bar-chart />
    <o-table v-if="$store.getters['tenants/list'].length > 0"
      :data="$store.getters['tenants/list']">
      <o-table-column field="client_key" label="client_key" v-slot="props">
        {{ props.row.client_key }}
      </o-table-column>
      <o-table-column field="expiration_date" label="expiration_date" v-slot="props">
        {{ props.row.expiration_date }}
      </o-table-column>
      <o-table-column field="company" label="company" v-slot="props">
        {{ props.row.company }}
      </o-table-column>
      <o-table-column field="active" label="active" v-slot="props">
        {{ props.row.active }}
      </o-table-column>
      <o-table-column field="available_licenses" label="available_licenses" v-slot="props">
        {{ props.row.available_licenses}}
      </o-table-column>
      <o-table-column field="used_licenses" label="used licenses" v-slot="props">
        {{ props.row.active_clients.length }}
      </o-table-column>
      <o-table-column v-slot="props">
        <o-button variant="success" @click="openDetailModal(props.row)">Details</o-button>
        <o-button variant="danger" @click="$store.dispatch('tenants/delete', props.row.client_key)">Delete</o-button>
        <o-button variant="warning" @click="openChangeModal(props.row)">Change</o-button>
      </o-table-column>
    </o-table>
  </div>
</template>

<script>
import ChangeTenantModal from './ChangeTenantModal.vue'
import DetailModal from './DetailModal.vue'

export default {
  methods: {
    openDetailModal (item) {
      this.$oruga.modal.open({
        component: DetailModal,
        props: { item: item }
      })
    },
    openChangeModal (item) {
      this.$oruga.modal.open({
        component: ChangeTenantModal,
        props: { item: item }
      })
    }
  }
}
</script>

<style>

</style>
