<template>
  <div class="about">
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <p class="title">
          Tentant Configuration
        </p>
      </div>
    </section>
    <br>
    <div class="columns">
      <div class="column">
        <o-field label="">
          <o-button variant="success" @click="create()">Create Tenant</o-button>
        </o-field>
        <o-field label="">
          <o-checkbox variant="success" v-model="$store.state.tenants.showInactive">Show inactive clients</o-checkbox>
        </o-field>
         <div v-if="$store.state.license.availableLicenses === -1">Total Available Licenses: unlimited</div>
         <div v-else>Total Available Licenses: {{ $store.state.license.availableLicenses }}</div>
         <div >used Licenses: {{ $store.state.license.usedLicenses }}</div>
      </div>
      <div class="column chart-container">
          <bar-chart :chartData="$store.getters['tenants/showUsedLicenseChartData']"/>
      </div>
      <div class="column">
        <div class="chart-container">
          <bar-chart :chartData="$store.getters['tenants/showAvailableLicensesChartData']"/>
        </div>
      </div>
    </div>

    <tenant-table></tenant-table>
  </div>
</template>

<script lang="js">
import TenantTable from '@/components/TenantTable.vue'
import CreateTenantModal from '@/components/CreateTenantModal.vue'
import BarChart from '@/components/BarChart.vue'

export default {
  components: {
    TenantTable,
    BarChart
  },
  mounted () {
    this.$store.dispatch('tenants/fetchAll')
  },
  methods: {
    create () {
      this.$oruga.modal.open({
        component: CreateTenantModal,
        trapFocus: true
      })
    }
  }
}
</script>

<style scoped>
</style>
