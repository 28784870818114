<template>
  <div>
    <o-table :data="Object.entries(this.$store.state.labels)">
      <o-table-column field="label_key" label="label_key" v-slot="props">
        {{ props.row[1].label_key }}
      </o-table-column>
      <o-table-column field="reference_name" label="reference_name" v-slot="props">
        <o-input v-model="props.row[1].reference_name"></o-input>
      </o-table-column>
      <o-table-column field="label_values" label="label_values" v-slot="props">
        <o-inputitems variant="primary" v-model="props.row[1].label_values" placeholder="Add an item" aria-close-label="Delete this item"> </o-inputitems>
      </o-table-column>
      <o-table-column field="save" label="save" v-slot="props">
        <o-button variant="success" @click="save(props.row[1])">Save</o-button>
      </o-table-column>
    </o-table>
    <o-button variant="success" @click="save()">Save</o-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelList: []
    }
  },
  mounted () {
    this.labelList = Object.entries(this.$store.state.labels)
  },
  methods: {
    save (data) {
      this.$store.dispatch('setLabel', data)
    }
  }
}
</script>

<style>

</style>
