<template>
  <div class="about">
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <p class="title">
          Label Configuration
        </p>
      </div>
    </section>
    <br>
    <label-table></label-table>
  </div>
</template>

<script lang="js">
import LabelTable from '@/components/LabelTable.vue'

import { useMsal } from '../composition-api/useMsal'
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { reactive, onMounted, watch } from 'vue'
import { tokenRequest } from '../authConfig'

export default {
  components: {
    LabelTable
  },
  setup () {
    const { instance, accounts, inProgress } = useMsal()
    const state = reactive({
      resolved: false,
      data: {}
    })
    return { instance, inProgress, state }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      const response = this.instance.acquireTokenSilent({
        ...tokenRequest
      }).catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          this.instance.acquireTokenSilent(tokenRequest)
        }
        throw e
      })
        .then(response => {
          this.$store.dispatch('getAllLabels', response.accessToken)
        })
    }
  }
}
</script>
